<template>
  <div class="userInfo">
    <div class="bg"></div>
    <div class="sign-bg">
      <div class="info-top">
        <div class="top-img flex between center">
          <img class="pointer" src="@/assets/sign-e.png" @click="toHome()" />
          <div class="pointer">
            <span @click="outLogin">退出登陆</span> |
            <span @click="toHome()">首页</span>
          </div>
        </div>
        <div class="top-info flex between">
          <div class="flex top-info-head">
            <img src="@/assets/head.png" v-if="info.avatar == ''" />
            <img :src="avatar" v-else />
            <div>
              <div>{{ info.truename }}</div>
              <div>{{ info.id_number }}</div>
            </div>
          </div>
          <div
            class="top-info-nav flex pointer"
            :class="{ 'top-info-nav-a': type == 1 }"
            @click="type = 1"
          >
            <img src="@/assets/info-icon1-a.png" v-if="type == 1" />
            <img src="@/assets/info-icon1.png" v-else />
            <div v-if="roterType == 1">报名信息</div>
            <div v-else>考试报名</div>
          </div>
          <div
            class="top-info-nav flex pointer"
            :class="{ 'top-info-nav-a': type == 2 }"
            @click="type = 2"
          >
            <img src="@/assets/info-icon2-a.png" v-if="type == 2" />
            <img src="@/assets/info-icon2.png" v-else />
            <div>我的资料</div>
          </div>
        </div>
      </div>
      <div class="info-type1" v-if="type == 1">
        <div class="userList" v-if="roterType != 1 && examInfo != ''">
          <div class="list-tit">可报名</div>
          <table class="table">
            <tr class="trBg">
              <td>考试名称</td>
              <td style="width: 30%">网上报名时间</td>
              <td style="width: 15%" v-if="examInfo.exam_time_desc != ''">
                考试时间描述
              </td>
              <td>操作</td>
            </tr>
            <tr>
              <td>{{ examInfo.name }}</td>
              <td style="padding: 0 10px">
                {{ examInfo.apply_start_time }}-{{ examInfo.apply_end_time }}
              </td>
              <td v-if="examInfo.exam_time_desc != ''">
                {{ examInfo.exam_time_desc }}
              </td>
              <td>
                <div v-if="starSta > 0">报名还未开始</div>
                <div v-else>
                  <div v-if="endSta < 0">报名已结束</div>
                  <div class="tdbtn pointer" v-else @click="toApply(1)">
                    开始报名
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="userList">
          <div class="list-tit">报名中</div>
          <table class="table" v-if="applyMyList.length != 0">
            <tr class="trBg">
              <td>姓名</td>
              <td>身份证号</td>
              <td>考试名称</td>
              <!-- <td>考试类型</td> -->
              <td>报名情况</td>
              <!-- <td style="width: 15%" v-if="item.exam.exam_time_desc != ''">
                考试时间描述
              </td> -->
              <td>操作</td>
            </tr>
            <tr v-for="(item, index) in applyMyList" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.id_number }}</td>
              <td>{{ item.exam.name }}</td>
              <!-- <td>{{ item.exam_type_text }}</td> -->
              <td :class="{ status2: item.check_status.value == 2 }">
                {{ item.check_status.text }}
                <span v-if="item.check_content != ''"
                  >（{{ item.check_content }}）</span
                >
                <span v-if="item.pay_status != 0"
                  >（{{
                    item.pay_status == 1
                      ? "待缴费"
                      : item.pay_status == 2
                      ? "已缴费"
                      : item.pay_status == 4
                      ? "已退款"
                      : item.pay_status == 3
                      ? "订单关闭"
                      : item.pay_status == 5
                      ? "申请退款中"
                      : item.pay_status == 6
                      ? "退款驳回"
                      : ""
                  }}）</span
                >
              </td>
              <td>
                <div
                  class="tdbtn pointer"
                  @click="toApply(2, item.id, item.exam.id)"
                  v-if="item.check_status.value == 3"
                >
                  重新报考
                </div>
                <div v-else>
                  <div v-if="item.registration_count > 0">
                    <div
                      class="tdbtn pointer"
                      @click="toList(item.id)"
                      v-if="item.exam.registration_print_limit == 0"
                    >
                      打印准考证
                    </div>
                    <div v-else>
                      <div
                        class="tdbtn pointer tdbtn2"
                        v-if="item.exam.star > 0 || item.exam.end < 0"
                        @click="
                          toShowTime(item.exam.registration_print_outdate_msg)
                        "
                      >
                        准考证打印时间
                      </div>
                      <div
                        class="tdbtn pointer"
                        @click="toList(item.id)"
                        v-else
                      >
                        打印准考证
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="tdbtn pointer"
                      @click="toPay(item.order_no, item.pay_amount)"
                      v-if="item.pay_status == 1&&item.status==3&&item.exam.pay_time_status==1"
                    >
                      去缴费
                    </div>
                    <div
                      class="tdbtn pointer tdbtn3"
                      @click="toRefund(item.order_no, item.pay_amount)"
                      v-else-if="item.pay_status == 2&&item.exam.pay_time_status==1"
                    >
                      申请退款
                    </div>
                    <div
                    class="flex"
                     
                      v-else-if="item.pay_status == 6&&item.exam.pay_time_status==1"
                    >
                    <div class="tdbtn pointer tdbtn3" @click="toRefund(item.order_no, item.pay_amount)">重新申请退款</div>
                    <div class="tdbtn pointer" @click="toRefund2(item.order_no)">驳回说明</div>
                      
                    </div>
                    <!-- <div
                      class="tdbtn pointer"
                      @click="toPay(item.order_no,item.pay_amount)"
                      v-if="item.pay_status == 4"
                    >
                      重新支付
                    </div> -->
                    <div v-else>-</div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div class="zan" v-else>暂无数据</div>
        </div>
      </div>
      <div class="info-type1 info-type2" v-if="type == 2">
        <div class="type2-tit">基础资料</div>
        <div class="type2-mian flex between">
          <div class="main-left">
            <!-- <el-button class="btn" @click="toggleShow" type="primary"
                >设置头像</el-button
              > -->
            <!-- <my-upload
              @crop-success="cropSuccess"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
              :modelValue.sync="show"
              :width="179"
              :height="206"
              img-format="png"
              :size="size"
              langType="zh"
              :noRotate="false"
              field="file"
              :url="urlUpdate"
            ></my-upload> -->

            <el-upload
              class="avatar-uploader"
              action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imgDataUrl" :src="imgDataUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <!-- <div class="img-tips">如报名时已上传照片 则不能更改</div> -->
            <div class="input-item flex">
              <div class="input-tit">姓名</div>
              <el-input v-model="name" placeholder="请输入姓名"></el-input>
            </div>
            <div class="input-item flex">
              <div class="input-tit">性别</div>
              <el-radio-group v-model="radio">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </div>
            <div class="input-item flex">
              <div class="input-tit">证件类型</div>
              <el-select v-model="value1" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="input-item flex">
              <div class="input-tit">证件编号</div>
              <el-input
                v-model="id_number"
                placeholder="请输入证件编号"
              ></el-input>
            </div>
            <div class="input-item flex">
              <div class="input-tit">出生日期</div>
              <el-date-picker
                v-model="value3"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
              <!-- <el-input
                v-model="name"
                placeholder="请选择出生日期"
                suffix-icon="el-icon-date"
              ></el-input> -->
            </div>
            <div class="input-item flex">
              <div class="input-tit">民族</div>
              <el-select v-model="value2" placeholder="请选择" @change="change">
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="sub-btn pointer" @click="toSave">保存</div>
          </div>
          <div class="main-right">
            <div>照片要求</div>
            <div v-html="zpyq.content">
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog title="详情" :visible.sync="dialogFormVisible">
      <div class="flex info-item">
        <div>姓名：</div>
        <div>{{ lookInfo.name }}</div>
      </div>
      <div class="flex info-item">
        <div>性别：</div>
        <div v-if="lookInfo.sex == 1">男</div>
        <div v-if="lookInfo.sex == 2">男</div>
      </div>
      <div class="flex info-item">
        <div>证件编号：</div>
        <div>{{ lookInfo.name }}</div>
      </div>
      <div class="flex info-item">
        <div>出生日期：</div>
        <div>{{ lookInfo.name }}</div>
      </div>
      <div class="flex info-item">
        <div>出生日期：</div>
        <div>{{ lookInfo.name }}</div>
      </div>
    </el-dialog>
    <!-- 微信支付 -->
    <div class="weiXinTan" v-if="showTan">
      <div class="tan-main">
        <div class="del-icon" @click="close()">
          <i class="el-icon-error"></i>
        </div>
        <img src="@/assets/free-bg.png" class="img-bg" />
        <div class="tan-tit">微信支付</div>
        <div class="order-num">支付金额：{{ pay_amount }}元</div>
        <img :src="qrcode" class="img-er" v-if="is_pay == 0" />
        <i class="el-icon-circle-check img-er2" v-if="is_pay == 1"></i>
        <div class="tan-btn" v-if="is_pay == 1">支付成功</div>
        <div class="tan-btn" v-if="is_pay == 0">打开微信扫一扫</div>
      </div>
    </div>
  </div>
</template>
<script>
import getFormat from "@/until/getTime.js";
import {
  userDetail,
  editInfo,
  examListDetail,
  categoryLists,
  applyMyList,
  applyDetail,
  positionDetail,
  orderPay,
  orderPayDetail,
  orderRefund,
} from "@/api/apiData";
import "babel-polyfill"; // es6 shim
// import myUpload from "vue-image-crop-upload";
export default {
  data() {
    return {
      fullscreenLoading: false,
      type: 1,
      roterType: "",
      avatar: "",
      name: "",
      radio: "1",
      options1: [
        {
          value: "选项1",
          label: "身份证",
        },
      ],
      value1: "", //证件类型
      options2: [
        {
          value: "选项1",
          label: "汉族",
        },
        {
          value: "选项2",
          label: "满族",
        },
      ],
      value2: "", //民族
      value3: "", //日期
      imgDataUrl: "",
      show: false,
      size: 2.1,
      imageUrl: "",
      urlUpdate: "",
      token: "",
      info: "",
      id_number: "",
      examInfo: "",
      nation_id: "",
      applyMyList: [],
      lookInfo: "",
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "80px",
      zpyq: "",
      starSta: "",
      endSta: "",
      showTan: false,
      qrcode: "",
      pay_amount: "",
      timer: null,
      is_pay: 0,
    };
  },
  mounted() {
    this.$bus.emit("navS", 0);
    this.getInfo();
    this.getExamInfo();
    categoryLists({ key: "nation" }).then((res) => {
      this.options2 = res.data;
    });
    this.getApply();
    let t = this.$route.query.type;
    if (t) {
      this.roterType = t;
    }
    this.getPositionDetail();
  },
  // components: {
  //   "my-upload": myUpload,
  // },
  created() {
    this.urlUpdate =
      "http://192.168.1.7/gaoxin_signup/public/index.php/api/upload/image"; //上传图片地址
  },
  methods: {
    // 支付
    toPay(id, pay_amount) {
      console.log(id);
      console.log(pay_amount);
      if (id == null) {
        this.$message.error("请等待审核通过");
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      orderPay({ order_no: id }).then((res) => {
        if (res.code == 1) {
          this.showTan = true;
          this.pay_amount = pay_amount;
          loading.close();
          this.qrcode = res.data.qrcode;
          // this.qrcode =
          // "https://gxrcexam.nccloudmedia.com/uploads/image/qrcode/2022-09-07/2022090729590776666929.png";
          this.timer = setInterval(() => {
            this.getOrderPayDetail(id);
          }, 1000);
        } else {
          loading.close();
          this.$message.error(res.msg);
        }
      });
    },
    // 申请退款
    toRefund(id, pay_amount) {
      let that = this;
      that
        .$prompt("请输入退款原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(({ value }) => {
          orderRefund({ order_no: id, reason: value }).then((res) => {
            if (res.code == 1) {
              that.$message({
                message: res.msg,
                type: "success",
              });
            that.getApply();
            } else {
              that.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    toRefund2(id){
      let that = this
      orderPayDetail({ order_no: id }).then((res) => {
        if (res.code == 1) {
          let reason = res.data.refund.check_content==''?'暂无说明':res.data.refund.check_content
         that.$alert(reason, '驳回说明', {
          confirmButtonText: '确定',
        });
        } else {
          
        }
      });
    },
    // 支付详情
    getOrderPayDetail(id) {
      orderPayDetail({ order_no: id }).then((res) => {
        if (res.code == 1) {
          if (res.data.is_pay == 1) {
            this.is_pay = 1;
            clearInterval(this.timer);
            this.timer = null;
            this.getApply();
            setTimeout(() => {
              this.showTan = false;
            }, 2000);
          }
        } else {
          clearInterval(this.timer);
          this.timer = null;
          this.$message.error(res.msg);
        }
      });
    },
    close() {
      this.showTan = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    // 照片需求
    getPositionDetail() {
      positionDetail({ posid: 6 }).then((res) => {
        if (res.code == 1) {
          this.zpyq = res.data;
        }
      });
    },
    // 记录列表
    getApply() {
      applyMyList().then((res) => {
        this.applyMyList = res.data.list;
        this.applyMyList.forEach((item) => {
          console.log(item.exam.registration_print_start_time);
          console.log(item.exam.registration_print_end_time);
          item.exam.star = this.GetDateDiff(
            item.exam.registration_print_start_time
          );
          item.exam.end = this.GetDateDiff(
            item.exam.registration_print_end_time
          );
        });
        console.log(this.applyMyList);
      });
    },
    // 打印准考证
    toPrint(id) {
      this.$router.push({
        path: "/printInfo",
        query: {
          id: id,
        },
      });
    },
    toShowTime(msg) {
      this.$alert(msg, "提示", {
        confirmButtonText: "确定",
        callback: (action) => {},
      });
    },
    // 获取考试详情
    getExamInfo() {
      let id = this.$route.query.id;
      if (id) {
        examListDetail({ exam_id: id }).then((res) => {
          if (res.code == 1) {
            if (res.data != null) {
              this.examInfo = res.data;
              let starSta = this.GetDateDiff(res.data.apply_start_time);
              let endSta = this.GetDateDiff(res.data.apply_end_time);
              this.starSta = starSta;
              this.endSta = endSta;
              console.log(starSta);
              console.log(endSta);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 获取用户详情
    getInfo() {
      userDetail().then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.name = res.data.truename;
          this.radio = res.data.sex;
          this.value3 = res.data.birthday;
          this.value1 = this.options1[0].label;
          if (res.data.nation != null) {
            this.value2 = res.data.nation.text;
            this.nation_id = res.data.nation.value;
          }
          if (res.data.avatar != "") {
            let url = "/" + res.data.avatar.split("/").slice(3).join("/");
            this.imgDataUrl = "https://gxrcexam.nccloudmedia.com" + url;
            this.imgDataPath = url;
            this.avatar = "https://gxrcexam.nccloudmedia.com" + url;
          }
          this.id_number = res.data.id_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 保存
    toSave() {
      let time = this.value3;
      if (typeof time === "string") {
        time = this.value3;
      } else {
        time = getFormat(this.value3);
      }
      let obj = {
        avatar: this.imgDataPath,
        truename: this.name,
        sex: this.radio,
        birthday: time,
        id_type: 1,
        id_number: this.id_number,
        nation_id: this.nation_id,
      };
      if (obj.avatar == "") {
        this.$message.error("请上传头像");
      } else {
        editInfo(obj).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.getInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    toUrl() {
      this.$router.push("/exam");
    },
    toHome() {
      this.$router.push("/index");
    },
    toApply(n, id, examId) {
      if (n == 2) {
        this.$router.push({
          path: "/apply",
          query: {
            id: examId,
            type: 2,
            examId: id,
          },
        });
      } else {
        this.$router.push({
          path: "/apply",
          query: {
            id: this.$route.query.id,
            type: 1,
          },
        });
      }
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl, field) {
      // console.log('-------- crop success --------',imgDataUrl, field);
    },
    //上传成功回调
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      this.imgDataUrl = jsonData.data.url;
      this.imgDataPath = jsonData.data.path;
      this.show = false;
    },
    //上传失败回调
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log("上传失败状态" + status);
      console.log("field: " + field);
    },
    handleAvatarSuccess(res, file) {
      if (file.response.code == 1) {
        let url = file.response.data.url;
        let path = file.response.data.path;
        this.imgDataUrl = url;
        this.imgDataPath = path;
        let width = file.response.data.width;
        let height = file.response.data.height;
        if (width > 305 || width < 285 || height > 423 || height < 403) {
          this.$message.error("请上传正确像素头像！");
          this.imgDataUrl = "";
          this.imgDataPath = "";
        }
        // if (width != 295 || height != 413) {
        //   this.$message.error("请上传正确像素头像！");
        //   this.imgDataUrl = "";
        //   this.imgDataPath = "";
        // }
      } else {
        this.$message.error("图片格式错误！请重新上传");
      }
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    // 退出登陆
    outLogin() {
      this.$confirm("确定要退出登陆吗~", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("token");
          this.$router.push("/signLogin");
        })
        .catch(() => {});
    },
    change(e) {
      this.nation_id = e;
    },
    // 查看详情
    toInfo(id) {
      applyDetail({ exam_apply_id: id }).then((res) => {
        if (res.code == 1) {
          this.lookInfo = res.data;
          this.dialogFormVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 判断时间差
    GetDateDiff(date) {
      var startDate = new Date().getTime();
      let da = date
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "")
        .replace(/(-)/g, "/");
      // new Date(data.replace(/-/g,'/')).getTime()
      // var  startDate = Date.parse(dateString1);
      var endDate = new Date(da).getTime();
      // var  endDate = Date.parse(dateString2);
      // var days = endDate;
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      // alert(days);
      return days;
    },
    GetDateDiff2(date) {
      var startDate = new Date().getTime();
      let da = date
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "")
        .replace(/(-)/g, "/");
      // var  startDate = Date.parse(dateString1);
      var endDate = new Date(da).getTime();
      // var  endDate = Date.parse(dateString2);

      var days = endDate - startDate;
      // alert(days);
      return days;
    },
    // 跳转准考证列表
    toList(id) {
      this.$router.push({
        path: "/printList",
        query: {
          id: id,
        },
      });
    },
  },
  beforeDestroy() {
    // 页面关闭（路由跳转）时清除定时器
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f2f5f8;
  z-index: -1;
}
.sign-bg {
  .info-top {
    background: white;
    box-shadow: 1px 1px 11px 1px #dfdfdf;
    .top-img {
      width: 60%;
      margin: 0 auto;
      border-bottom: solid 1px #d4d4d4;
      padding: 20px 0;
      img {
        width: 55%;
      }
      div {
        color: #0089e1;
        font-size: 16px;
      }
    }
    .top-info {
      width: 60%;
      margin: 0 auto;
      align-items: center;
      .top-info-head {
        align-items: center;
        line-height: 2;
        font-size: 14px;
        img {
          width: 77px;
          margin-right: 20px;
          border: solid 1px #14a1fd;
        }
      }
      .top-info-nav {
        color: #676f73;
        font-size: 18px;
        align-items: center;
        width: 20%;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .top-info-nav-a {
        color: #0089e1;
      }
    }
  }
  .info-type1 {
    width: 60%;
    margin: 0 auto;
    .userList {
      background: white;
      padding: 30px;
      border-radius: 10px;
      margin-top: 44px;
      .list-tit {
        font-size: 18px;
        color: #333333;
      }
      .table {
        width: 100%;
        text-align: center;
        border-bottom: solid 1px #e6e6e6;
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
        td {
          padding: 15px 0;
        }
        .trBg {
          background: #e5f5ff;
          color: #676f73;
          font-size: 16px;
        }
        .tdbtn {
          background: #1baefd;
          color: white;
          width: 90px;
          margin: 0 auto;
          height: 34px;
          line-height: 34px;
          border-radius: 5px;
        }
        .tdbtn2 {
          font-size: 12px;
          background: #285774;
          width: 120px;
        }
        .tdbtn3 {
          background: #ff9800;
        }
      }
    }
  }
  .info-type2 {
    background: white;
    margin-top: 20px;
    .type2-tit {
      color: #0089e1;
      font-size: 18px;
      padding: 12px 44px;
      border-bottom: solid 1px #e6e6e6;
    }
    .type2-mian {
      width: 70%;
      margin: 0 auto;
      .main-left {
        width: 50%;
        text-align: center;
        margin-top: 20px;
        .up {
          width: 80px;
          height: 80px;
          position: relative;
          top: 50%;
          margin-top: -40px;
          border: dashed 1px #dedede;
        }
        .img-tips {
          width: 126px;
          margin: 10px auto;
          font-size: 14px;
        }
        .input-item {
          align-items: center;
          font-size: 18px;
          margin: 20px 0;
          .input-tit {
            width: 20%;
            text-align: right;
            margin-right: 30px;
          }
        }
        .sub-btn {
          background: #1baefd;
          font-size: 14px;
          width: 90px;
          line-height: 34px;
          margin: 40px auto 30px;
          color: white;
          border-radius: 5px;
        }
      }
      .main-right {
        width: 45%;
        div:nth-child(1) {
          font-size: 18px;
          line-height: 4;
        }
        div:nth-child(2) {
          font-size: 14px;
          color: #afafaf;
        }
      }
    }
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 110px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: auto;
    margin: 0 auto;
    box-shadow: 0 3px 3px #e1d9d9;
    border: dashed 1px #707070;
    display: block;
  }
}
.info-item {
  font-size: 16px;
  margin-bottom: 15px;
  div:nth-child(1) {
    text-align: right;
    width: 80px;
  }
}
.status2 {
  color: #0089e1;
}
.weiXinTan {
  position: fixed;
  z-index: 5;
  background: rgba(0, 0, 0, 0.452);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .tan-main {
    position: relative;
    width: 418px;
    height: 514px;
    background: white;
    z-index: 2;
    .del-icon {
      position: absolute;
      color: #363535;
      font-size: 40px;
      right: -40px;
      top: -40px;
      cursor: pointer;
    }
    .img-bg {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
    .tan-tit {
      text-align: center;
      font-size: 18px;
      margin-top: 22px;
      font-weight: bold;
    }
    .order-num {
      text-align: center;
      font-size: 22px;
      margin-top: 50px;
      font-weight: bold;
      color: white;
    }
    .img-er {
      width: 226px;
      height: 226px;
      margin: 30px auto;
      display: block;
    }
    .img-er2 {
      width: 226px;
      height: 200px;
      margin: 30px auto;
      display: block;
      font-size: 10vw;
      color: #0089e1;
      position: relative;
      left: 1vw;
    }
    .tan-btn {
      font-size: 18px;
      color: #0089e1;
      width: 262px;
      height: 42px;
      line-height: 42px;
      border-radius: 20px;
      text-align: center;
      border: solid 1px;
      margin: 0 auto;
    }
  }
}
</style>